/**
 * imports
 */
import 'vite/modulepreload-polyfill';
import { createApp, defineComponent, onMounted, reactive } from 'vue';

/**
 * create vue instance function
 */
const createVueApp = () => {
  const app = createApp({
    setup() {
      defineComponent({
        components: {},
      });

      const data = reactive({
        loading: false,
        accountState: '',
      });

      // const useCustomer = useCustomerStore();
      // const { state } = storeToRefs(useCustomer);

      const updateAccountState = (pathname, async = false) => {
        data.loading = true;
        data.accountState = pathname;
        let url = window.location.origin + data.accountState;
        window.history.replaceState({ path: null }, '', url);
        if (!async) {
          data.loading = false;
        }
      };

      // const changeTab = (selectedTab) => {
      //   accountData.activeTab = selectedTab;
      // };

      onMounted(() => {
        data.accountState =
          window.location.pathname + window.location.hash;
      });

      return {
        data,
        updateAccountState
      };
    },
  });

  return app;
};

/**
 * create and mount vue instance(s)
 */
const vueLogin = document.querySelector('.vue__login');

if (vueLogin) {
  createVueApp({})
    .mount(vueLogin);
}

/**
 * fixes for Shopify sections
 * 1. properly render vue components on user insert in the theme editor
 * 2. reload the current page to rerender async inserted sections with vue components
 *
 * add the 'vue' keyword to the section's wrapper classes if the section uses any vue functionality e.g.:
 * {% schema %}
 * {
 *   "class": "vue-section"
 * }
 * {% endschema %}
 */
if (window.Shopify.designMode) {
  document.addEventListener('shopify:section:load', (event) => {
    if (event.target.classList.value.includes('vue')) {
      createVueApp().mount(event.target);
    }
  });
} else if (
  !window.Shopify.designMode &&
  process.env.NODE_ENV === 'development'
) {
  new window.MutationObserver((mutationsList) => {
    mutationsList.forEach((record) => {
      const vue = Array.from(record.addedNodes).find(
        (node) => node.classList && node.classList.value.includes('vue')
      );
      if (vue) window.location.reload();
    });
  }).observe(document.body, {
    childList: true,
    subtree: true,
  });
}
